export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const AUTH_TOKEN = 'auth_token'
export const USER_INFO = 'user_info'