import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${match.url}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`${match.url}/reports`} component={lazy(() => import(`./reports`))} />
        <Route path={`${match.url}/elearning`} component={lazy(() => import(`./elearning-management`))} />
        <Route path={`${match.url}/setting`} component={lazy(() => import(`./Setting`))} />
        <Redirect from={`${match.url}`} to={`${match.url}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;
