import { SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Raja Edutech';
export const API_BASE_URL = env.API_ENDPOINT_URL

export const THEME_CONFIG = {
	"navType": NAV_TYPE_SIDE,
	"sideNavTheme": SIDE_NAV_DARK,
	"navCollapsed": false,
	"topNavColor": "#3e82f7",
	"headerNavColor": "#3e82f7",
	"locale": "en"
};
