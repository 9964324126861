import {SettingOutlined, CodeSandboxOutlined, ReadOutlined, DashboardOutlined, ReconciliationOutlined, YoutubeOutlined } from '@ant-design/icons';

const dashBoardNavTree = [{
  key: 'home',
  path: '/app/dashboards',
  title: 'home',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: '/app/dashboard/sales',
      title: 'sidenav.home.dashboard',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
  ]
}]

const elearningManagementNavTree = [{
  key: 'elearning',
  path: '/app/elearning',
  title: 'sidenav.elearning',
  icon: YoutubeOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'elearning-package',
      path: '/app/elearning/package',
      title: 'sidenav.elearning.package',
      icon: CodeSandboxOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'media-group',
      path: '/app/elearning/media-group',
      title: 'sidenav.elearning.media-group',
      icon: YoutubeOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'media-item',
      path: '/app/elearning/media-item',
      title: 'sidenav.elearning.media-item',
      icon: YoutubeOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'ebook',
      path: '/app/elearning/ebook',
      title: 'sidenav.elearning.ebook',
      icon: ReadOutlined,
      breadcrumb: true,
      submenu: []
    }
  ]
}]

const reportsNavTree = [{
  key: 'reports',
  path: '/app/reports',
  title: 'sidenav.reports',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'orders',
      path: '/app/reports/orders',
      title: 'sidenav.reports.orders',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'product-analytics',
      path: '/app/reports/product-analytics',
      title: 'sidenav.reports.product-analytics',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: []
    },
  ]
}]

const settingNavTree = [
  {
    key: 'setting',
    path: '/app/setting',
    title: 'sidenav.setting',
    icon: SettingOutlined,
    breadcrumb: true,
    submenu: []
  }
]

const navigationConfig = [
  ...dashBoardNavTree,
  ...elearningManagementNavTree,
  ...reportsNavTree,
  ...settingNavTree
]

export default navigationConfig;
